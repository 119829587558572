"use client";

import React, { useEffect, useRef } from "react";
import { cn } from "../../helpers/classNames";
import { pushDataLayer } from "../../helpers/dataLayer";
import ClientOnly from "../ClientOnly";
import { sendError } from "../ErrorBoundary/helpers";
import { Arrow } from "./arrow";
import styles from "./styles.module.scss";

export type SliderContext = "topLocations" | "forecastNavigation" | "miamap";
type Props = {
    children: React.ReactNode;
    minHeight?: string;
    dataTracking?: string;
    className?: string;
    context?: SliderContext;
};

export const Slider: React.FC<Props> = ({
    children,
    minHeight = "200px",
    dataTracking,
    className,
    context,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (dataTracking) {
            const sliderRef = ref.current;
            const scrollEnd = (): void => {
                pushDataLayer({
                    type: "scrollEnd",
                    event: "slider_scroll_end",
                    slider: dataTracking,
                });
            };
            sliderRef?.addEventListener("scrollend", scrollEnd);

            return (): void => {
                sliderRef?.removeEventListener("scrollend", scrollEnd);
            };
        }
        return (): void => {};
    }, [dataTracking]);

    try {
        return (
            <div className={styles.wrapper}>
                {context ? (
                    <ClientOnly>
                        <Arrow
                            direction="left"
                            sliderRef={ref}
                            context={context}
                        />
                    </ClientOnly>
                ) : null}
                <div
                    style={{ minHeight }}
                    className={cn(styles.slider, className)}
                    data-tracking-slider={dataTracking}
                    ref={ref}
                >
                    {children}
                </div>
                {context ? (
                    <ClientOnly>
                        <Arrow
                            direction="right"
                            sliderRef={ref}
                            context={context}
                        />
                    </ClientOnly>
                ) : null}
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
