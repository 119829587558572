import React from "react";
import { cn } from "../../helpers/classNames";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./styles.module.scss";

const colorMap = {
    blueDark: "--wcom-c-blueDark",
    yellow: "--wcom-c-yellow",
};

type Props = {
    size: number;
    color?: keyof typeof colorMap;
    className?: string;
};

export const SpinningSun: React.FC<Props> = ({
    size,
    color = "yellow",
    className,
}) => {
    try {
        return (
            <div className={cn(styles.wrapper, className)}>
                <div
                    className={cn(styles.spinning, "icon-sun-hours")}
                    style={{
                        fontSize: `${size.toString()}px`,
                        width: `${size.toString()}px`,
                        height: `${size.toString()}px`,
                        color: `var(${colorMap[color]})`,
                    }}
                />
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
